import React from "react";
import Slider from "react-slick";
import "./guide.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import assets
import Num1 from "../../assets/number-1.svg";
import Num2 from "../../assets/number-2.svg";
import Num3 from "../../assets/number-3.svg";
import Num4 from "../../assets/number-4.svg";
import M1 from "../../assets/client-step1.svg";
import M2 from "../../assets/client-step2.svg";
import M3 from "../../assets/client-step3.svg";
import M4 from "../../assets/client-step4.svg";

const Guide = () => {
  return (
    <section
      id="guide"
      className="guide-section"
    >
      <Container className="guide-container">
        <div className="header_1 text-center guide-title">
          How it <span className="highlight">works.</span>
        </div>

        <div>
          <p className="text-center subhead guide-subhead">
          Be discovered, build your reputation, and be booked all in one platform. 
          </p>
        </div>

        {guideData.map((guide, idx) => (
          <Row key={guide.id} className="mx-auto mb-4 desktop">
            <Col xs={12} md={6} lg={6} xl={6}>
              <div className="media-container">
                {guide.mediaType === "image" ? (
                  <img
                    src={guide.mediaSrc}
                    alt={guide.title}
                    className="guide-image"
                    width="754"
                    height="342"
                  />
                ) : (
                  <video
                    controls
                    width="754"
                    height="342"
                    autoPlay
                    muted
                    loop
                  >
                    <source src={guide.mediaSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            </Col>
            <Col
              xs={12}
              md={1}
              lg={1}
              xl={1}
              className="text-center num-column-trainer"
            >
              <img
                src={guide.num}
                alt={guide.title}
                className="img-fluid"
              />
            </Col>
            <Col xs={12} md={5} lg={5} xl={5} className="text-left">
              <div className="header_4">{guide.title}</div>
              <div className="body_text">
                {" "}
                {guide.text.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
        ))}
        <Row className="mx-auto mb-4">
          <Col
            xs={12}
            md={12}
            lg={12}
            xl={12}
            className="justify-content-center"
          >
            <Row className="mobile">
              <div className="mobile">
                <GuideCarousel guideData={guideData} />
              </div>
            </Row>
            <div className="trainee-start">
              <Row>
                <div className="start-block header_2">
                  The "You Have Nothing to Lose" Guarantee
                </div>
                <div className="body_text text-center mt-2">
                  If you don't earn money from your website in the first year, we'll completely refund you. No questions asked.
                </div>
              </Row>
              <Row className="justify-content-center">
                <a
                  href="https://forms.gle/d1DdvDXfde5AkzjFA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn-start-trainee btn_text">
                    Try it free today!
                  </button>
                </a>
              </Row>
              <Row>
                <p className="label_text">We'll build you a site in less than 24 hours, no card required.</p>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const GuideCarousel = ({ guideData }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {guideData.map((guide, idx) => (
        <>
          <Row >
            <div className="media-container-mobile">
              <img
                src={guide.mediaSrc}
                alt={guide.title}
                className="guide-image"
                width="754"
                height="342"
              />
            </div>
          </Row>
          <Row className="text-container-mobile">
            <Col xs={3} className="num-column">
              <img src={guide.num} alt={guide.title} className="img-fluid" />
            </Col>
            <Col xs={9}>
              <div className="header_4">{guide.title}</div>
              <div className="body_text">
                {" "}
                {guide.text.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
        </>
      ))}
    </Slider>
  );
};

export default Guide;

const guideData = [
  {
    id: 1,
    num: Num1,
    title: "Establish your online brand",
    text: "Get an AI-generated website launched within a day and start being discovered online.\n\n• Modern design that converts\n• SEO-optimized to show up in searches\n• Automatically synced to your online profiles like Instagram and Facebook\n• Show up in local searches",
    mediaType: "image",
    mediaSrc: M1,
  },
  {
    id: 2,
    num: Num2,
    title: "Build a stellar online reputation",
    text: "Enable more opportunities to find you online. \n• Opportunities texted to you (and only you) \n• immediately Contact information stored automatically for easy follow up \n• Automated, customized messaged \n• Streamlined, on-time payment collection",
    mediaType: "image",
    mediaSrc: M2,
  },
  {
    id: 3,
    num: Num3,
    title: "Turn website visitors into clients",
    text: "Spend less time on the phone and enable more customers to book and pay for services online. \n• Opportunities texted to you (and only you) immediately \n• Contact information stored automatically for easy follow up \n• Automated, customized messaged \n• Streamlined, on-time payment collection",
    mediaType: "image",
    mediaSrc: M3,
  },
  {
    id: 4,
    num: Num4,
    title: "Enable customers to pay for your services online",
    text: "Streamlined, on-time payment collection through your website, email, or text. Process credit cards with ease. Meet your customers where they are\n• Effortless follow-ups to be paid faster\n• Quick payouts\n• Earn predictable, monthly revenue (with rewards for your clients.)\n• Industry-standard low fees paid by you or your customers, whichever you prefer",
    mediaType: "image",
    mediaSrc: M4,
  },
];

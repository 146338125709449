import React, { useState, useEffect } from "react";
import "./footer.css";
import DojoLogo from "../../assets/brand/dojo-master-logo.png";
import { IoLogoInstagram } from "react-icons/io5";
import { RiFacebookCircleFill } from "react-icons/ri";
import ReactMarkdown from 'react-markdown';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"

import validator from "validator";

// const PrivacyPolicy = () => {
//   return (
//     <div>
//       <ReactMarkdown>{PP}</ReactMarkdown>
//     </div>
//   )
// }

const PrivacyPolicy = () => {
 const [content, setContent] = useState('');

 useEffect(() => {
    // Adjust the path as necessary
    fetch('/privacy-policy.md')
      .then(response => response.text())
      .then(data => {
        setContent(data);
      })
      .catch(error => console.error('Error fetching privacy policy:', error));
 }, []);

 return (
    <div>
      {/* Render the privacy policy content with markdown styling */}
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
 );
};


const Footer = () => {
  const [emailError, setEmailError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const validateEmail = (e) => {
    const email = e.target.value;

    if (!validator.isEmail(email)) {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("");
    }
  };

  return (
    <footer>
      <Container className="footer-container">
        <Row>
          <Col xs={12} sm={12} md={1} lg={1} xl={1}>
 
               <img
              src={DojoLogo}
              alt="Dojo"
              className="footer-logo"
            />

          </Col>
          <Col xs={12} sm={12} md={3} lg={3} xl={3} className="footer-contact">
            <div className="body_text_bold mb-2">Contact Us:</div>
            <ul className="body_text list-unstyled">
              <li className="mb-2">
                <a href="mailto:calebchoi00@gmail.com" target="_blank" rel="noreferrer">
                  calebchoi00@gmail.com
                </a>
              </li>
              <li>631-624-1871</li>
            </ul>
            <div className="footer-socials">
              <a href="https://www.facebook.com/profile.php?id=100095518088839&mibextid=PtKPJ9" rel="noreferrer">
                <RiFacebookCircleFill />
              </a>
              <a href="https://www.instagram.com/dojolessons/" rel="noreferrer">
                <IoLogoInstagram />
              </a>
            </div>
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
            <ul className="permalinks">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a>About</a>
              </li>
              <li>
                <a href="#guide">How it works</a>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
            <ul className="permalinks">
              <li>
                <a>Sign up as an athlete</a>
              </li>
              <li>
                <a>Refund Policy</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="d-flex justify-content-end me-0 footer-form">
              <Form className="footer-form">
                <Form.Group>
                  <Form.Label className="label_text form-label-text">
                    Email
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Your Email"
                    onChange={(e) => validateEmail(e)}
                    className="body_text form-placeholer-text"
                  />
                  <Form.Text className="text-danger">{emailError}</Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="label_text form-label-text">
                    Message
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Questions, comments, concerns?"
                    className="body_text form-placeholder-text"
                  />
                </Form.Group>
                <Button className="btn-form-footer mx-auto d-block">
                  Submit
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="footer-links label_text">
              <button className="text-decoration-none btn-modal">Terms of Service</button>
              <button className="text-decoration-none btn-modal" onClick={handleShowModal}>Privacy Policy</button>
              </div>
              <div className="footer-cr label_text">@ 2024 NILPilot, Inc. All rights reserved</div>
            
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dojo Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrivacyPolicy />
        </Modal.Body>

      </Modal>

    </footer>
  );
};

export default Footer;

import React from "react";
import "./testimonials.css";
import Luca from "../../assets/trainers/Luca.jpeg";
import Santiago from "../../assets/trainers/Santiago.png";
import Drew from "../../assets/trainers/Andrew.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import QuoteIcon from "../../assets/quote-icon.svg";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonials-section">
      <Container className="testimonials-container">
        <Row>
         <img
            src={QuoteIcon}
            alt="Quote Icon"
            className="quote-icon img-fluid"
          ></img>


          <div className="header_2 text-center testimonial-title">
            Why our athletes trust us.
          </div>
          <div className="centered-hr">
            <hr />
          </div>
        </Row>
        <Row>
          <TestimonialSlider />
        </Row>
      </Container>
    </section>
  );
};

// const NextBtn = (props) => {
//   const { className, onClick } = props;

//   return (
//     <div className={className} onClick={onClick}>
//       <IoIosArrowForward style={{color: "var(--color-N500)", fontSize:"45px"}} />
//     </div>
//   )
// }
const NextBtn = (props) => {
  const { className, onClick } = props;

  return (
    <div>
      <IoIosArrowForward
        style={{ color: "var(--color-N500)", fontSize: "45px" }}
        className={className}
        onClick={onClick}
      />
    </div> // when className and onClick are removed from div and placed in arrow component, it appropriately
    // removes the original arrows.... but inhibits changing the size?
  );
};

const PrevBtn = (props) => {
  const { className, onClick } = props;

  return (
    <div>
      <IoIosArrowBack
        style={{ color: "var(--color-N500)", width: "40px" }}
        className={className}
        onClick={onClick}
      />
    </div>
  );
};

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextBtn />,
    prevArrow: <PrevBtn />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
          autoplay: false,
        },
      },
    ],
  };
  return (
    <div className="testimonials-slider">
      <Slider {...settings}>
        {testimonialsData.map((t) => (
          <Card key={t.id} className="testimonial-card justify-content-center align-items-center">
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <Card.Text className="testimonial-card-text body_text">
              {t.review}
              </Card.Text>
              <div className="testimonial-image-container">
                <img
                  src={t.photo}
                  alt={t.name}
                  className="testimonial-card-img"
                />
              </div>
              <Card.Text className="header_5 mb-1 mt-2">{t.name}</Card.Text>
              <Card.Subtitle className="body_text">
                {t.subtitle}
              </Card.Subtitle>
            </Card.Body>
          </Card>
        ))}
      </Slider>
    </div>
  );
};

const testimonialsData = [
  {
    id: 1,
    name: "Luca",
    photo: Luca,
    subtitle: "Tennis, UFC",
    review:
      "I came to Dojo to try it out, and I ultimately discovered a variety of ways I could earn money and give lessons doing what I love.",
  },
  {
    id: 2,
    name: "Santiago",
    photo: Santiago,
    subtitle: "Tennis, UCF",
    review:
      "Dojo is a smooth, easy way to make some side income while at university. It was great because they find you the clients and you just show up and teach.",
  },
  {
    id: 1,
    name: "Drew",
    photo: Drew,
    subtitle: "Football, UCF",
    review:
      "Dojo makes it easy to make money through NIL opportunities. It's also great because they allow you to try it for free and have a money back guarantee.",
  },
];

export default Testimonials;

import React, { useState } from "react";
import "./faq.css";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";

import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";

const Faq = () => {
  const [toggleInstructorsFaq, setToggleInstructorsFaq] = useState(false);

  const handleToggle = (val) => {
    setToggleInstructorsFaq(val);
  };

  return (
    <section id="faq" className="faq-container">
      <Container className="d-flex flex-column align-items-center">
        <div className="header_2 faq-header">FAQ</div>
        <div className="justify-content-center">
          <ToggleSwitch
            isToggled={toggleInstructorsFaq}
            onToggle={handleToggle}
            id="faqToggleSwitch"
          />
        </div>
        {toggleInstructorsFaq ? (
          <Accordion defaultActiveKey="0" flush className="custom-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="body_text_bold">
                How do I connect with athletes? 
              </Accordion.Header>
              <Accordion.Body className="body_text">
              Just use <a href="nilpilot.carrd.co" target="_blank" rel="noopener noreferrer">this link</a>. It's free!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="body_text_bold">
                How does it work?
              </Accordion.Header>
              <Accordion.Body className="body_text">
                Fill out the form, and we'll connect you to the athlete who is the best fit for your needs. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="body_text_bold">
                How much does it cost? 
              </Accordion.Header>
              <Accordion.Body className="body_text">
                It's free to connect with the athlete! Prices are determined by the athlete. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="body_text_bold">
                Why should I use NILPilot?
              </Accordion.Header>
              <Accordion.Body className="body_text">
               We have a strong network of NCAA athletes throughout the country who may be a good fit for your needs. Fill out the form above to get connected and learn more! 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="body_text_bold">
                Where do lessons take place?
              </Accordion.Header>
              <Accordion.Body className="body_text">
                Lesson locations are determined by the athlete who may offer remote or in-person lessons. 
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          // trainer faq
          <Accordion defaultActiveKey="0" flush className="custom-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="body_text_bold">
                How does it work? 
              </Accordion.Header>
              <Accordion.Body className="body_text">
              NILPilot creates a custom-made SEO-optimized website for you using AI in less than 24 hours. You can request edits at any time. We bring customers to you.We offer a 100% money back guarantee if you don't make any profit in 6 months. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="body_text_bold">
                How much does it cost?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              NILPilot is free to use and only charges you once you start to get opportunities. To unlock the full feature set, it is $19.99/month.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="body_text_bold">
                How do I get in touch with clients?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              We'll send you directly new opportunities and clients as they come in via text. You can set up calls and respond directly via text or via the NILPilot dashboard. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="body_text_bold">
                What kind of opportunities will I get?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              We want the best for our athletes, so we'll connect you with opportunities that are a good fit for you. Whether that is lessons, camps, singings, or brand deals, we'll help you find the best opportunities!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="body_text_bold">
                What if I want to try it out? is it free? 
              </Accordion.Header>
              <Accordion.Body className="body_text">
              Yes! It is free to try and we only charge for accessing opportunities. We offer a 100% money back guarantee if you don't make any profit in 6 months. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header className="body_text_bold">
                Why should I use NILPilot?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              We'll not only create a custom-made SEO-optimized website for you using AI in less than 24 hours, but we'll also bring customers to you. We'll connect you to a network of like-minded athletes. We also help with developing your social profile and help you navigate the NIL landscape to ensure you are compliant.  
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header className="body_text_bold">
                What if I want to cancel my membership? Are there refunds?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              Yes! If you don't make any profit in 6 months, we'll refund your membership fee. 
              <br/><br/>
              Try us today, risk-free! 100% satisfaction guaranteed!
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </Container>
    </section>
  );
};

export default Faq;

import React, {useEffect} from 'react'
import Slider from "react-slick";
import "./trainerCarousel.css"

import Card from 'react-bootstrap/Card'

import Andrew from "../../assets/trainers/Andrew.png"
import Emilio from "../../assets/trainers/Emilio Image.avif"
import GabeF from "../../assets/trainers/Gabe F.jpeg"
import GabeT from "../../assets/trainers/Gabe T.jpeg"
import Jacson from "../../assets/trainers/Jacson.avif"
import Luca from "../../assets/trainers/Luca.jpeg"
import Riana from "../../assets/trainers/Riana_9.26.png"
import Santiago from "../../assets/trainers/Santiago.png"


const TrainerCarousel = () => {
  const settings= { 
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

 useEffect(() => {
  console.log('Trainer Carousel mounted')
 }, [])

  return (
    <div className="trainer-slider">
<Slider {...settings}>
      {trainerData.map((t) => (
          <Card className="trainer-card text-white" key={t.id}>
          <Card.Img src={t.img} className="trainer-card-img" alt={t.name}/>
          <Card.ImgOverlay className="trainer-card-overlay text-center">
            <Card.Title className="trainer-card-title header_5">{t.name}</Card.Title>
            <Card.Text className="trainer-card-subtitle subhead_fs"> 
              {t.text}
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      ))}
</Slider> 
  </div>
  )
}

export default TrainerCarousel


const trainerData = [
  {
    id: 1,
    name: "Drew",
    img: Andrew,
    text: "Division-1 wide recceiver at UCF",
    alt: "Football player at UCF"
  },
  {
    id: 2,
    name: "Emilio",
    img: Emilio,
    text: "D1 tennis player, ranked 65th in US",
    alt: "Tennis player ranked 65th in US"
  },
  {
    id: 3,
    name: "Riana",
    img: Riana,
    text: "Division II, WABA basketball players",
    alt: "Former Division II basketball player"
  },
  {
    id: 4,
    name: "Gabe F",
    img: GabeF,
    text: "Division 1 soccer player at SBU",
    alt: "Division 1 soccer player at SBU"
  },
  {
    id: 5,
    name: "Gabe T",
    img: GabeT,
    text: "Former D1 athlete and current pro",
    alt: "Tennis instructor who is a current pro"
  },
  {
    id: 6,
    name: "Jacson",
    img: Jacson,
    text: "Division 1 and former Burnley Academy player",
    alt: "Soccer instructor who is a Division 1 player"
  },
  {
    id: 7,
    name: "Luca",
    img: Luca,
    text: "Division 1 tennis player at UCF",
    alt: "Tennis player at UCF"
  },
  {
    id: 8,
    name: "Santiago",
    img: Santiago,
    text: "Division 1, 1st Team All-WAC, ranked #7 in Florida",
    alt: "Tennis player at UCF, ranked #7 in Florida"
  },
]
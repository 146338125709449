import React from 'react';
import './toggleSwitch.css'; // Ensure this file contains the updated CSS

const ToggleSwitch = ({ isToggled, onToggle, id }) => {
  

  const toggleSwitch = () => {
    onToggle(!isToggled)
  }

  return (
    <div className="btn-container">
    <label className="switch btn-user-mode-switch">
      <input
        type="checkbox"
        name="user_mode"
        id={id}
        value="1"
        checked={isToggled}
        onChange={toggleSwitch}
      />
      <label
        htmlFor={id}
        data-on="For Athletes"
        data-off="For Clients"
        className="btn-user-mode-switch-inner"
      ></label>
    </label>
  </div>
  );
};

export default ToggleSwitch;


import React, { useState, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import Slider from "react-slick";
import "./header.css";

import Container from "react-bootstrap/Container";
// import Card from "react-bootstrap/Card";

import Soccer from "../../assets/AC Milan Pro Soccer 1.PNG";
import Tennis from "../../assets/Baruch Tennis Trainer.PNG";
import Wrestling from "../../assets/NYU Wrestling Trainer.PNG"
import Baseball from "../../assets/NYU Baseball 4.PNG"
import Swim from "../../assets/NYU Swim 1.PNG"

import SmDiagonal from "../../assets/Sm-Diagonal.svg";
import LgDiagonal from "../../assets/Lg-Diagonal.svg";


const heroSlides = [
  {
    id:1,
    img: Soccer
  },
  {
    id: 2,
    img: Tennis
  },
  {
    id: 3,
    img: Wrestling
  },
  {
    id: 4,
    img: Baseball
  },
  {
    id: 5,
    img: Swim
  }
]

const Header = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  return (
    <header>
      <Container className="header_container">
        <div className="lg-header-diagonal-container">
          <img src={LgDiagonal} alt="LgDiagonal" className="lg-diagonal" />
        </div>
        <TextAnimation currentSlide={currentSlide} />

        <div className="header-subhead subhead">
          <p>
            NILPilot is the{" "}
            <span style={{ fontWeight: 700 }}>easiest</span>{" "}
            way to make money online while remaining NIL compliant. Start by receiving a fully customized website and become discovered & paid.  
          </p>
        </div>

        <a
          href="https://forms.gle/d1DdvDXfde5AkzjFA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-header btn_text">Continue</button>
        </a>
        <p className="label_text header-label">
          See a draft of your website. It's free!
        </p>

        <HeaderCarousel onSlideChange={handleSlideChange} />
        <div className="sm-header-diagonal-container">
          <img src={SmDiagonal} alt="Diagonal" className="sm-diagonal-svg" />
        </div>
      </Container>
    </header>
  );
};

/* ------- HEADER CAROUSEL ------- */
const HeaderCarousel = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: 10,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: 5,
        },
      },
    ],
  };

  useEffect(() => {
    console.log('HeaderCarousel mounted');
    // Any additional setup or logging here
  }, []); // Empty dependency array means this runs once on mount


  return (
    <div className="hero-slider">
      <Slider {...settings}>
        {heroSlides.map((img, idx) => (
          // make one image larger
          <div key={img.id} className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img.img} alt={img.img} className="hero-card" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

/* ------- ORIGINAL TYPE ANIMATION ------- */
const TextAnimation = () => {
  return (
    <div className="display">
      {""}
      <TypeAnimation
        sequence={[
          "The all-in-one platform for",
          5000, // how long it stays
          "Monetize your skills as a",
          5000,
          "Teach lessons as a",
          5000,
        ]}
        wrapper="span"
        speed={150} //type speed
        repeat={Infinity}
        cursor={false}
      />
      <br />
      <span className="highlight">NCAA Athletes</span>
    </div>
  );
};

export default Header;

import React from 'react'
// import Contact from '../../../src/components/contact/Contact'
import Guide from '../../../src/components/guide/Guide'
import Header from '../../../src/components/header/Header'
import Testimonials from '../../../src/components/testimonials/Testimonials'
import Trainers from '../../../src/components/trainers/Trainers'
import Faq from '../../../src/components/faq/Faq'

function Landing() {
  return (
  <>
  <Header />
  <Trainers />
  <Guide />
  <Testimonials />
  <Faq />
  {/* <Contact /> */}
  </>
  );
}

export default Landing